export const BreakupContents = () => {
    return (
        <div className="body-content-row">
          <div className="body-content-column">
            <div className="body-content-container">
              <div className="body-content-section">
                  When you’re a high-level executive, it’s hard to imagine life after a career disruption. After all, you’ve spent years building something significant. But real success isn’t just about climbing ladders—it’s about standing firm in your identity. And right now, that identity feels shaken.
              </div>
              <div className="body-content-section">
                  If you’re feeling disconnected from the confidence, influence, and purpose that once defined you, it’s time for a breakup.
              </div>
            </div>
          </div>
          <div className="body-content-column">
            <div className="body-content-container">
              <div className="body-content-section">
                  Changing your relationship with the people, places, and patterns that no longer serve you is utterly transformative. It means stepping into a new era of leadership—one rooted in clarity, courage, and authenticity. Whether you’re looking to rebuild, reposition, or completely redefine your career, I can help.
              </div>
              <div className="body-content-section">
                  Not everyone is ready. But if you’re willing to turn uncertainty into expansion, you’re not everyone.
              </div>
              <div className="body-content-section big-font">
                  You’re breakup material.
              </div>
            </div>
          </div>
        </div>
    )

}

