export const viewModel = {
  tabKeys: ["paradox", "breakup", "approach", "ready"],
  home: {
    firstName: "Elizabeth",
    lastName: "Aldana",
    titleTop: "Breakup",
    titleBottom: "Artist",
    imgSrc: "home_4_shop.png",
    mobileImgSrc: "home_4_shop.png",
    subtitleShort: "I help executives get their careers back on track.",
  },
  paradox: {
    tabTitle: "Success, Interrupted",
    bodyTitle: "Success, Interrupted",
    bodySubtitle: "The Awakening in The Wilderness",
  },
  breakup: {
    tabTitle: "Breakup Artistry in Action",
    bodyTitle: "Breakup Artistry in Action",
    bodySubtitle: "Your Breakthrough is Personal",
  },
  approach: {
    tabTitle: "My Approach",
    bodyTitle: "Why Elizabeth?",
    bodySubtitle: "Your partner in transformational growth",
    imgSrc: "sunny_smiley_shop.png",
  },
  ready: {
    tabTitle: "I Am Ready",
    bodyTitle: "Let's Start Your Transformation",
    bodySubtitle: "Your career isn't over. It's evolving.",
    imgSrc: "home_1.jpeg",
    img: "ready_img_final.png",
    videoSrc: "butterfly.mp4",
  },
  testimonials: [
    {
      key: 1,
      name: "GH",
      content: "Elizabeth brings a natural talent and skillset to her coaching work that not only brings value, it improves so much beyond your direct work.  She is kind, empathetic, insightful, and smart; and she most definitely has a gift for her craft.",
    },
    {
      key: 2,
      name: "JW",
      content:
        "In spite of having a very high profile job in my industry, I was feeling frustrated and burned out in my career – then I started coaching with Elizabeth.  After just one introductory session I already felt clarity on issues that had been swirling around in my head for months. Her continued guidance then helped me find a renewed sense of focus and direction in my career. I still see her for regular tune-ups and check in’s to keep me focused on the next steps in my journey.",
    },
    {
      key: 3,
      name: "MM",
      content:
        "That change at work that I figured would take months to sort out? Done in a couple days. That nuanced and emotionally charged familial stress and strain that felt like it would take a lifetime to tackle? Handled in mere weeks. By pushing me to seek clarity around these opaque and problematic obstacles in my life and instrument a conscious break-up with them, Elizabeth, in effect, bent time and helped me pull my eventual conclusions from the distant future into my present.",
    },
    {
      key: 4,
      name: "NR",
      content:
        "After working with Elizabeth, I felt ready and empowered to tackle critical and time-sensitive tasks for both my business and my clients. The session gave me the momentum I needed to move forward effectively.",
    },
    {
      key: 5,
      name: "FL",
      content: `I achieved a stronger awareness of myself. I’m used to being the one asking the questions, so Elizabeth's direct line of questioning, structured approach, and superior reflective listening skills were a breath of fresh air and much needed to gain further introspection.`,
    },
    {
      key: 6,
      name: "TS",
      content: `Elizabeth has the uncanny ability to make problems feel manageable and light—all while providing a safe place to explore whatever you’re thinking.
      Where there was chaos, I now have clarity. And where there was doubt, I now see opportunity and fun. If you’re on the fence about life coaching, I wholeheartedly recommend trying it out with Elizabeth. I don’t know where I’d be without her.
      `,
    },
    {
      key: 7,
      name: "AG",
      content: `I now have such a better envisionment and confidence for what may now come. Less stressed, more optimistic, and more embedded in my own unique skill set. It takes a special talent, a whole soapy cauldron of empathy, listening, methodologies training, career navigating, and interpersonal communication, to get you into a strong outcome headspace. Elizabeth has all those abilities in full.`,
    },
    {
      key: 8,
      name: "HK",
      content: `I'm in the process of building out my dream career, in the midst of my present one, and my time with Elizabeth helped me to streamline my goals and action items to make my dream a reality. One thing that I find very noteworthy is that she is an incredibly intelligent listener and I was very impressed by how she was able to catch the little details in my rants to her as well as repeat it back to me in her own words.`,
    },
    {
      key: 9,
      name: "NN",
      content: `I would recommend Elizabeth's coaching services to anyone who is at a crossroads and could use perspective. During coaching, you have the opportunity to think through problems with someone who’s in your corner but can still remain objective. Rather than criticize or praise certain ideas, Elizabeth uses the Socratic method to bring your attention to things you might have missed.`,
    },
  ],
};
