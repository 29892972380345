export const ParadoxContents = () => {
    return (
        <div>
          <div className="body-content-row single-column">
            <div className="body-content-column">
              <div className="body-content-container">
                <div className="body-content-section big-font">
                    If you’re a walking billboard for success but suddenly find yourself displaced—forced out, misaligned, or navigating an unexpected career shift—congratulations.
                </div>
                <div className="body-content-section big-font">
                    You’re not headed for a breakdown.  You’re headed for a breakup.*
                </div>
              </div>
            </div>
          </div>
          <div className="body-content-row top-margin">
            <div className="body-content-column">
              <div className="body-content-container">
                <div className="body-content-section">
                    You built an extraordinary career. Boardrooms, big decisions, and a well-earned reputation. Then, the unexpected happened. Maybe it was a re-org. A merger. A shift in company culture that no longer aligned with your values. Now, you’re caught between identities—who you were, and who you must become.
                </div>
              </div>
            </div>
            <div className="body-content-column">
              <div className="body-content-container">
                <div className="body-content-section">
                    Believe it or not, this is SUCH a good place to be in.
                    <br />
                    <br />
                    It means your next chapter—the one where you reclaim your legacy—is about to begin.
                </div>
                <div className="body-content-section very-small-text">
                  *Breakup: Changing your relationship with a person, place, or pattern that no longer serves you. 
                </div>
              </div>
            </div>
          </div>
        </div>
    )

}
