import { useContext } from 'react'
import { AppContext } from "./AppContext"

export const ApproachContents = () => {
    const { viewModel } = useContext(AppContext);

    return (
        <div className="approach-contents">
          <div className="body-content-row single-column">
            <div className="body-content-column">
              <div className="body-content-container">
                <div className="body-content-section">
                  <div className="approach-header">
                    <img alt="Why Elizabeth?" className="approach-img" src={viewModel.approach.imgSrc} />
                    <div className="approach-header-text">
                      You don’t just need a career coach—you need someone who can look at your entire trajectory and see two things: The common thread of authenticity that’s been buried, and the common thread of inauthenticity that’s keeping you stuck.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="body-content-row top-margin">
            <div className="body-content-column">
              <div className="body-content-container">
                <div className="body-content-section no-top-margin">
                    I know exactly what it feels like to have your professional identity shaken. And I can promise you this: Your career isn’t over. Your influence isn’t lost. Your legacy isn’t broken.
                </div>
                <div className="body-content-section auto-wrap">
                    As a Breakup Artist, I help high-achieving executives move from uncertainty to momentum by creating a safe, trusted space to strategize bold, fearless steps forward. With compassion, clarity, and candor (even when it’s tough), I help you become lighter, more focused, and ready for decisive action.
                </div>
              </div>
            </div>
            <div className="body-content-column">
              <div className="body-content-container">
                <div className="body-content-section">
                    If you’re ready to take back control and redefine your next chapter, I can help.
                </div>
                <div className="body-content-section">
                    Because your brilliance isn’t lost. It’s waiting on the other side of your breakup.
                </div>
              </div>
            </div>
          </div>
        </div>
    )

}
